import checkmark from 'assets/icons/checkmark_24.svg';
import empty from 'assets/icons/empty_24.svg';
import { useNavbarContext } from 'contexts/NavbarContext';
import pricingTable from 'data/pricing/pricingTable';
import React from 'react';

import {
   StyledContainer,
   Table,
   TableLegend,
   Tbody,
   THeader,
} from './PricingTable.styled';

const PricingTable = () => {
   const { isMenuVisible, navbarHeight } = useNavbarContext();
   const topOffset = isMenuVisible ? navbarHeight : 0;

   return (
      <StyledContainer id="pricing-section">
         <TableLegend>
            <li>
               <span>S</span> - Student
            </li>
            <li>
               <span>T</span> - Test&Go
            </li>
            <li>
               <span>R</span> - Rocket
            </li>
         </TableLegend>

         <Table>
            {pricingTable.map((table, i) => (
               <Tbody key={i}>
                  <tr>
                     <td colSpan={i === 0 ? '1' : '4'}>{table.name}</td>
                     {i === 0 ? (
                        <>
                           <THeader className="head" topOffset={topOffset}>
                              Student
                           </THeader>
                           <THeader className="head" topOffset={topOffset}>
                              Test&Go
                           </THeader>
                           <THeader className="head" topOffset={topOffset}>
                              Rocket
                           </THeader>
                        </>
                     ) : null}
                  </tr>
                  {table.details.map((item, index) => (
                     <tr key={index}>
                        <td>{item.category}</td>
                        <td>
                           {item.student ? (
                              <img src={checkmark} alt="checkmark" />
                           ) : (
                              <img src={empty} alt="not checked" />
                           )}
                        </td>
                        <td>
                           {item.testGo ? (
                              <img src={checkmark} alt="checkmark" />
                           ) : (
                              <img src={empty} alt="not checked" />
                           )}
                        </td>
                        <td>
                           {item.rocket ? (
                              <img src={checkmark} alt="checkmark" />
                           ) : (
                              <img src={empty} alt="not checked" />
                           )}
                        </td>
                     </tr>
                  ))}
               </Tbody>
            ))}
         </Table>
      </StyledContainer>
   );
};

export default PricingTable;

export default [
   {
      name: 'Planowanie',
      details: [
         {
            category: 'Tworzenie szablonów zadań głównych',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: true,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Tworzenie szablonów podzadań',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: true,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Tworzenie bazy sprzętów',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: true,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Tworzenie bazy dokumentów',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: true,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Przydzielanie uprawnień',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: true,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Ustawianie zadań cyklicznych',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Umieszczanie zadań z wyprzedzeniem',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Przypisywanie zadań do pracowników',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Tworzenie bazy pracowników',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Tworzenie bazy zleceń i zamówień',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Tworzenie bazy zasobów magazynowych',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Samodzielne nadawanie dostępu do systemu',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: false,
            rocket: true,
         },
      ],
   },
   {
      name: 'Operacje',
      details: [
         {
            category: 'Tworzenie formularzy elektronicznych',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: true,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Przypisywanie podzadań do zadań głównych',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: true,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Wprowadzanie danych w formularzach elektronicznych',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: true,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Wprowadzanie danych do walidacji',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: true,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Inteligentne statusy realizowanych zadań',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: true,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Zatwierdzanie wyników pojedyncze',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: true,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Zapis historii operacji',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: true,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Wyszukiwarka',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: true,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Zatwierdzanie wyników blokowe',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Alerty o stanie zasobów',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Filtrowanie zadań i podzadań',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Wprowadzanie wyników wielokrotnych',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Tagowanie formularzy elektronicznych',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: false,
            rocket: true,
         },
         {
            category: 'Wprowadzenie kodów kreskowych i QR',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: false,
            rocket: true,
         },
         {
            category: 'Połączenie pól formularzy funkcjami',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: false,
            rocket: true,
         },
      ],
   },
   {
      name: 'Raportowanie',
      details: [
         {
            category: 'Tworzenie raportów z dowolnego okresu czasu',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: true,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Eksport danych do MS Excel',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: true,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Eksport danych do CSV',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: true,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Tworzenie raportów w oparciu o filtry',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Tworzenie listy ulubionych raportów',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: true,
            rocket: true,
         },
         {
            category: 'Eksport danych do MS Word / PDF',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: false,
            rocket: true,
         },
         {
            category: 'Wysyłka raportów bezpośrednio z systemu',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: false,
            rocket: true,
         },
         {
            category: 'Tworzenie szablonów raportów i sprawozdań',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: false,
            rocket: true,
         },
      ],
   },
   {
      name: 'Dedykowane funkcjonalności',
      details: [
         {
            category: 'Wprowadzenie kodów kreskowych',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: false,
            rocket: true,
         },
         {
            category: 'Wprowadzenie kodów QR',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: false,
            rocket: true,
         },
         {
            category: 'Import danych do systemu',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: false,
            rocket: true,
         },
         {
            category: 'Audyt biznesowy przed implementacją',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: false,
            rocket: true,
         },
         {
            category: 'Dostęp na urządzeniach mobilnych do całego systemu',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: false,
            rocket: true,
         },
         {
            category: 'Sporządzenie listy dedykowanych funkcjonalności',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: false,
            rocket: true,
         },
         {
            category: 'Wdrożenie dedykowanych funkcjonalności',
            tooltip:
               'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts',
            student: false,
            testGo: false,
            rocket: true,
         },
      ],
   },
];

import styled from 'styled-components';
import { colors, transitions, breakpoints } from 'variables';

export const Question = styled.dt`
   font-size: 1.8rem;
   color: ${colors.black};
   font-weight: bold;
   padding: 30px 0;
   cursor: pointer;
   user-select: none;
   position: relative;
   margin-left: 40px;

   &:before {
      content: '';
      width: 12px;
      height: 12px;
      border-bottom: 1px solid ${colors.black};
      border-right: 1px solid ${colors.black};
      transform: ${({ isOpen }) =>
         isOpen ? 'rotate(45deg)' : 'rotate(-45deg)'};
      position: absolute;
      left: -40px;
      top: 0;
      bottom: 0;
      margin: auto;
      transition: transform ${transitions.ease};
   }

   ${breakpoints.md`
      font-size: 2.2rem;
   `}
`;

export const Answer = styled.dd`
   max-height: ${({ isOpen }) => (isOpen ? '500px' : '0px')};
   transition: max-height ${transitions.ease};
   overflow: hidden;
   font-size: 1.4rem;
   color: ${colors.black};
   line-height: 26px;
   border-bottom: 1px solid #e3e3e9;

   ${breakpoints.md`
      font-size: 1.6rem;
   `}
`;

import Container from 'components/shared/Container';
import styled from 'styled-components';
import FAQItem from './FAQItem';
import React, { useState } from 'react';

const List = styled.ul`
   margin-top: 50px;
   display: flex;
   flex-direction: column;
   width: 100%;
`;

const FAQList = ({ list }) => {
   const [currentQuestion, setCurrentQuestion] = useState(null);

   return (
      <Container>
         <List>
            {list.map((item, i) => (
               <FAQItem
                  key={i}
                  index={i}
                  item={item}
                  isOpen={i === currentQuestion ? true : false}
                  currentQuestion={currentQuestion}
                  setCurrentQuestion={setCurrentQuestion}
               />
            ))}
         </List>
      </Container>
   );
};

export default FAQList;

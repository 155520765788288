import React from 'react';

export default [
   {
      question: 'Jak mogę zalogować się do systemu?',
      answer: [
         <p>
            Wyślij do nas formularz zgłoszeniowy. Zaznacz, którym pakietem
            jesteś zainteresowany. Czekaj na hasło do systemu. Zaloguj się przez
            przeglądarkę i gotowe!
         </p>,
      ],
   },
   {
      question: 'Jak zacząć korzystać z LabControl?',
      answer: [
         <p>
            Po zalogowaniu się do LabControl powinieneś wprowadzić do systemu
            dane niezbędne do codziennej pracy:
         </p>,
         <ol>
            <li>Stworzyć szablon / szablony zadań.</li>
            <li>Stworzyć szablon / szablony podzadań.</li>
            <li>Ustawić uprawnienia dla poszczególnych ról w systemie.</li>
            <li>Umieścić zadania i podzadania w harmonogramie.</li>
         </ol>,
         <p>
            Możesz to wszystko zrobić samodzielnie lub skontaktować się z nami w
            celu poprowadzenia krok po kroku.
         </p>,
         <p>
            Dostępna jest także pomoc w formie audytu biznesowego, który
            przeprowadzimy dla Ciebie w celu pełnego przygotowania systemu pod
            potrzeby Twojej organizacji. Jeśli jesteś zainteresowany, skontaktuj
            się poprzez formularz kontaktowy.
         </p>,
      ],
   },
   {
      question: 'Czym jest okres próbny pakietu Test&GO?',
      answer: [
         <p>
            Okres próbny to 14 dni kalendarzowych, w których zupełnie za darmo
            możesz korzystać z systemu w wybrany dla Ciebie sposób. Po
            zakończeniu tego okresu skontaktujemy się z Tobą w celu
            przedstawienia oferty komercyjnej.
         </p>,
      ],
   },
   {
      question: 'Jak działa polityka cenowa?',
      answer: [
         <p>
            LabControl działa w modelu subskrypcyjnym z płatnością w formie
            przelewu. Finalna cena uzależniona jest od wybranego pakietu, liczby
            użytkowników oraz liczby kupowanych licencji.
         </p>,
         <p>
            Cena 1000 pln netto / miesięcznie dotyczy jednej licencji dla 5-ciu
            użytkowników (loginów) w systemie. Możesz wykorzystać ich na
            początku mniej, cena nie wzrośnie dopóki nie poprosisz o dostęp dla
            6-tego użytkownika.
         </p>,
         <p>
            Bezpłatny pakiet Student dotyczy subskrypcji dla uczelni i szkół na
            okres 10 miesięcy (jednego roku szkolnego/akademickiego). Po tym
            okresie cena za licencję jest ustalana indywidualnie i uzależniona
            jest od liczby użytkowników i ilości planowanych pakietów.
         </p>,
         <p>
            Cena dla pakietu Rocket jest ustalana indywidualnie na podstawie
            zebranych od Ciebie wymagań.
         </p>,
      ],
   },
   {
      question: 'Jak zrezygnować z licencji LabControl?',
      answer: [
         <p>
            W przypadku rezygnacji z subkrypcji skontaktuj się znami przez
            formularz kontaktowy lub swojego opiekuna (w pakiecie Rocket).
            Licencja będzie obowiązywała do końca trwającego okresu
            rozliczeniowego.
         </p>,
      ],
   },
   {
      question:
         'Jak zamówić audyt biznesowy zanim zacznę korzystać z LabControl?',
      answer: [
         <p>
            Skontaktuj się z nami poprzez formularz kontaktowy. Opiekun
            LabControl przedstawi Ci szczegółowy przebieg audytu i uzgodni
            dogodny termin jego przeprowadzenia.
         </p>,
      ],
   },
];

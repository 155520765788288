import styled from 'styled-components';
import { colors } from 'styles/variables';

export const ContactParagraph = styled.p`
   margin-top: 10px;
   padding-top: 23px;
   width: 100%;
   text-align: center;
   a {
      color: ${colors.brand};
   }
`;

import Container from 'components/shared/Container';
import routes from 'data/globals/routes';
import { Link } from 'gatsby';
import React from 'react';
import { ContactParagraph } from './Contact.styled';

const Contact = () => {
   return (
      <Container as="section">
         <ContactParagraph>
            <strong>Masz wątpliwości?</strong>
            <Link to={routes.contact}> Skontaktuj się</Link>
         </ContactParagraph>
      </Container>
   );
};

export default Contact;

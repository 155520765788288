import styled from 'styled-components';
import { colors, transitions, breakpoints } from 'variables';

import Container from 'components/shared/Container';

export const StyledContainer = styled(Container)`
   flex-direction: column;
   margin-top: 30px;

   ${breakpoints.lg`
      margin-top: 90px;
      flex-direction: row;
   `}
`;

export const TableLegend = styled.ul`
   margin-bottom: 20px;
   justify-content: center;
   display: flex;
   background-color: ${colors.lightBlue};

   li {
      display: inline-block;
      padding: 10px 10px;
      font-size: 1.6rem;

      span {
         font-weight: bold;
         color: ${colors.brand};
      }
   }

   ${breakpoints.md`
      display: none;
   `}
`;

export const Table = styled.table`
   width: 100%;
   border-collapse: collapse;

   tbody {
      tr {
         border-bottom: 1px solid ${colors.greyMap.get(400)};

         &:first-of-type {
            border: 0;

            td:first-of-type {
               font-size: 2.2rem;
               font-weight: 700;
               color: ${colors.black};
               max-width: auto;
            }
         }

         td {
            text-align: center;
            padding: 15px 10px;

            &:first-of-type {
               font-size: 1.4rem;
               text-align: left;
               img { display: none; }
            }
         }
      }
   }

   ${breakpoints.md`
      tbody tr:not(:first-of-type) {
         border: 0;

         td {
            font-size: 1.4rem;
            &:first-of-type img {
               display: inline;
               margin-left: 10px;
               vertical-align: middle;
            }
         }
      }
   `};

`;

export const THeader = styled.td`
   font-weight: 400;
   color: ${colors.brand};
   top: ${({ topOffset }) => `${topOffset}px`};
   position: sticky;
   background-color: white;
   font-size: 0;
   transition: top ${transitions.ease};

   &:first-letter { font-size: 2.2rem; }

   ${breakpoints.md`
      min-width: 100px;
      max-width: 200px;
      font-size: 2.2rem;
   `}
`;

export const Tbody = styled.tbody`
   &:before {
      content: '';
      display: block;
      height: 60px;
   }

   &:first-of-type {
      &:before {
         height: 0;
      }
   }
`;

import React from 'react';

import SectionHeader from 'components/shared/SectionHeader';

import FAQList from 'components/pages/pricing/FAQList';
import Contact from '../Contact';
import pricingFaq from 'data/pricing/pricingFaq';

const FAQSection = () => {
   const sectionText = [<strong>Masz pytania?</strong>];

   return (
      <>
         <SectionHeader id="faq" sectionText={sectionText} />

         <FAQList list={pricingFaq} />
         <Contact />
      </>
   );
};

export default FAQSection;

import FAQSection from 'components/pages/pricing/FAQSection';
import PricingSection from 'components/pages/pricing/PricingSection';
import PackagesList from 'components/shared/PackagesSection/PackagesList';
import PageHeader from 'components/shared/PageHeader';
import SEO from 'components/shared/SEO';
import routes from 'data/globals/routes';
import React, { useEffect } from 'react';
import scrollTo from 'utilities/scrollTo';

const Pricing = ({ location }) => {
   const headerText = ['Wybierz pakiet ', <strong>dla siebie</strong>];

   const sectionId = location.state?.sectionId;

   useEffect(() => {
      if (!sectionId) {
         return;
      }
      const timeout = setTimeout(() => {
         scrollTo(sectionId);
      }, 100);

      return () => {
         clearTimeout(timeout);
      };
   }, [sectionId]);

   const packagesLinks = {
      link: {
         text: 'Poznaj pełne porównanie',
         to: 'pricing-section',
      },
      buttons: [
         {
            text: 'Wybierz Student',
            to: routes.contact,
            state: { package: 'Student' },
         },
         {
            text: 'Wybierz Test&Go',
            to: routes.contact,
            state: { package: 'Test&Go' },
         },
         {
            text: 'Wybierz Rocket',
            to: routes.contact,
            state: { package: 'Rocket' },
         },
      ],
   };

   return (
      <>
         <SEO title="Cennik" />
         <PageHeader headerText={headerText} />

         <PackagesList packagesLinks={packagesLinks} />

         <PricingSection />
         <FAQSection />
      </>
   );
};

export default Pricing;

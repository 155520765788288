import React, { Fragment } from 'react';

import { Question, Answer } from './FAQItem.styled';

const FAQItem = ({
   item,
   index,
   isOpen,
   currentQuestion,
   setCurrentQuestion,
}) => {
   const { question, answer } = item;

   const handleClick = () => {
      if (currentQuestion === index) {
         setCurrentQuestion(null);
         return;
      }
      setCurrentQuestion(index);
   };

   return (
      <>
         <Question onClick={handleClick} isOpen={isOpen}>
            {question}
         </Question>

         <Answer isOpen={isOpen}>
            {answer.map((p, i) => (
               <Fragment key={i}>{p}</Fragment>
            ))}
         </Answer>
      </>
   );
};

export default React.memo(FAQItem);

import React from 'react';

import SectionHeader from 'components/shared/SectionHeader';
import PricingTable from 'components/pages/pricing/PricingTable';

const PricingSection = () => {
   const sectionText = [<strong>Porównaj</strong>, ' pakiety'];

   return (
      <>
         <SectionHeader id="pakiety" sectionText={sectionText} />

         <PricingTable />
      </>
   );
};

export default PricingSection;
